import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"
import * as errors from "../src/js/error_helper"

export default class extends Controller {
  static targets = [
    "abn",
    "payee",
    "bsb",
    "accountNumber",
    "bankDetail",
    "bankDetailConfirmation",
    "bankDetailDisbursmentToBeConfirmedMessage",
    "noBankDetailDisbursmentMessage",
    "noPayeeFoundMessage"
  ]

  fetchPayees() {
    utils.hide(this.bankDetailTarget)
    utils.hide(this.noBankDetailDisbursmentMessageTarget)
    this.payeeTarget.innerHTML = ""

    const abnInput = this.abnTarget.value
    const sanitizeAbnInput = abnInput.replace(/[ -]/g, "")

    if (/^\d{11}$/.test(sanitizeAbnInput)) {
      let url = new URL(this.data.get("payee-searches-url"))
      url.searchParams.append("abn", sanitizeAbnInput)
      fetch(url.toString())
        .then(response => {
          if (response.error) throw new Error("Something went wrong")
          return response.json()
        })
        .then(data => {
          this.populatePayees(data);
        })
        .catch(error => {
          console.error("Error while fetching payee data:", error)
        })
    } else {
      if (sanitizeAbnInput.length > 11){
        utils.show(this.noPayeeFoundMessageTarget)
      } else {
        utils.hide(this.noPayeeFoundMessageTarget)
      }
    }
  }

  populatePayees(data) {
    this.payeeTarget.innerHTML = ""
    utils.hide(this.bankDetailTarget)
    const blankOption = document.createElement("option")
    blankOption.value = ""
    if (Object.keys(data).length == 0) {
      utils.show(this.noPayeeFoundMessageTarget)
    } else {
      utils.hide(this.noPayeeFoundMessageTarget)
      blankOption.textContent = "Select a payee"
      this.payeeTarget.appendChild(blankOption)
      for (const [id, name] of Object.entries(data)) {
        const option = document.createElement("option")
        option.value = id
        option.textContent = name
        this.payeeTarget.appendChild(option)
      }
    }
  }

  fetchBsbAndAcountNumber(event) {
    const payeeElement = event.currentTarget
    const payeeSelectedOption = payeeElement.selectedOptions[0]
    let url = new URL(payeeElement.dataset.payeeBankAccountSearchesUrl)
    url.searchParams.append("payee_id", payeeSelectedOption.value)
    fetch(url.toString())
      .then(response => {
        if (response.error) throw new Error("Something went wrong when retrieving bank details")
        return response.json()
      })
      .then(data => {
        inputs.set_radio_value(this.bankDetailConfirmationTarget, "")
        this.bsbTarget.textContent = data.bsb
        this.accountNumberTarget.textContent = data.account_number
        utils.hide(this.bankDetailDisbursmentToBeConfirmedMessageTarget)
        if (this.accountNumberTarget.textContent == '') {
          utils.hide(this.bankDetailTarget)
          utils.show(this.noBankDetailDisbursmentMessageTarget)
        } else {
          utils.hide(this.noBankDetailDisbursmentMessageTarget)
          if (this.data.get("asset-supplier-dealer-company-id") == payeeSelectedOption.value) {
            inputs.set_radio_value(this.bankDetailConfirmationTarget, this.data.get("confirmed-asset-supplier-bank-account-confirmation-code"))
          }
          utils.show(this.bankDetailTarget)
          utils.toggle(this.bankDetailDisbursmentToBeConfirmedMessageTarget, inputs.get_choice_from_radio_group(this.bankDetailConfirmationTarget) != 'contradicted')
        }
        errors.clearErrorFromLabelledRadios(this.bankDetailConfirmationTarget)
      })
      .catch(error => {
        console.error("Error while fetching data:", error)
      })
  }

  bankDetailConfirmationChanged(event) {
    let choice = inputs.get_choice_from_radio_click(event)
    utils.hide(this.noBankDetailDisbursmentMessageTarget)
    if (choice == "contradicted") {
      utils.show(this.bankDetailDisbursmentToBeConfirmedMessageTarget)
    } else {
      utils.hide(this.bankDetailDisbursmentToBeConfirmedMessageTarget)
    }
  }
}
