import { Controller } from "@hotwired/stimulus"
import * as utils from "../src/js/stimulus_helper"
export default class extends Controller {
  static targets = [
    "addressSelection",
    "selectedFullAddress",
    "manualAddressEntrySection",
    "manualEntryAddress",
    "addressLookUpSection",
    "manualEntryCheckBox",
    "addressLookUpEntryCheckBox"
  ]

  connect() {
    // Select2 annoyingly doesn't fire native events, so we have to listen via a delegator
    $(this.addressSelectionTarget).on('select2:select', (e)=> this.address_selected(e))

  }

  address_selected(event) {
    const canonical_id = event.target.value
    // get the complete address from the selected option only
    var selector = `option[value="${canonical_id}"]`
    const selected_address = event.target.querySelector(selector).textContent
    this.selectedFullAddressTarget.value = selected_address
  }

  manualEntryRequestChanged(event){
    const checked = event.target.checked
    this.selectedFullAddressTarget.value = ''
    if(checked){
      this.addressLookUpEntryCheckBoxTarget.checked = false
      utils.show(this.manualAddressEntrySectionTarget)
      utils.hide(this.addressLookUpSectionTarget)
    }
  }

  addressLookupRequestChanged(event){
    const checked = event.target.checked
    this.selectedFullAddressTarget.value = ''
    if(checked){
      this.manualEntryCheckBoxTarget.checked = false
      utils.hide(this.manualAddressEntrySectionTarget)
      utils.show(this.addressLookUpSectionTarget)
    }
  }
}
